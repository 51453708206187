import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Description from "./Description";

import {colors, objects} from "../constants";
import edit from "../imgs/edit.svg";
import {price} from '../helper';
import classNames from 'classnames';
import print from "../imgs/print.svg";
import Popup from './Popup';


export default class ConfigFinal extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			countItems: 1,
			showPopUp: false,
		}
	}

	togglePopUp = (e) =>
	{
		e.preventDefault();
		this.setState((state) => ({showPopUp: !state.showPopUp}))
	}
	setStage = (e, num) =>
	{
		e.preventDefault();
		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
		} = this.props;
		if (num == 2)
		{
			if (!selectedFrame.winter)
			{
				return
			}
		}
		if (num == 5)
		{
			if (!selectedFrame.wallWindow)
			{
				return
			}
		}
		this.props.setStage(num);
	}

	toggleMenu = (e) =>
	{
		e.preventDefault();
		this.props.toggleMenu();
	}

	counter = (changer) => 
	{
		this.setState((state) =>
		{
			let newValue = state.countItems + changer;

			return {countItems: newValue > 0 ? newValue : 1}
		})
	}



	_exportPdf = () =>
	{
		html2canvas(this.print).then(canvas =>
		{
			//document.body.appendChild(canvas);  // if you want see your screenshot in body.
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF({
				orientation: window.innerWidth < 1280 ? "p" : "l",
				//unit: "in",
				//format: [4, 2]
			});
			pdf.addImage(imgData, 'PNG', 0, 0);
			pdf.save("download.pdf");
		});
	}

	render()
	{
		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
			isMenuOpen,
			currStage
		} = this.props;
		const {
			countItems,
			showPopUp
		} = this.state;

		let totalCount = 7;
		if (!selectedFrame.winter)
		{
			totalCount--
		}
		if (!selectedFrame.wallWindow)
		{
			totalCount--
		}

		let count = (selectedFrame ? 3 : 0) + (winter ? 1 : 0) + (curtains ? 1 : 0) + (roofWindow ? 1 : 0) + (wallWindow ? 1 : 0);
		let percent = Math.ceil(count / totalCount * 100);

		let total = (selectedFrame ? selectedFrame.framePrice + selectedFrame.colorPrice : 0) +
			(winter ? winter.price : 0) +
			(curtains ? curtains.price : 0) +
			(roofWindow ? roofWindow.sum : 0) +
			(wallWindow ? wallWindow.sum : 0);
		let isFinal = currStage == 6;


		return (
			<>
				<div
					ref={node => this.print = node}
					className={classNames("config", {"open": isMenuOpen, "final": isFinal})}
				>
					<h3 className="title">
						Ваша конфигурация
					</h3>
					<div className="name">Модуль: {selectedFrame?.frame}</div>
					<div className="separator">
						<div className="right">
							<div className="img">
								<img src={colors[selectedFrame.selectedColor].imgUrl} alt={selectedFrame.selectedColor} />
							</div>
							{/* <a href="#" className="print" onClick={e =>
							{
								e.preventDefault();
								this._exportPdf();
							}}>
								Распечатать
								<img src={print} alt="Распечатать" />
							</a> */}
						</div>
						<div className="left">

							<table className='value'>
								<tbody>
									<tr>
										<td className="desc">
											Каркас: <span>{selectedFrame.frame} м</span>
										</td>

										<td><span>{selectedFrame.frame} м</span></td>
										<td className='price'></td>
										<td></td>
									</tr>
									<tr>
										<td className="desc">Тент: <span>{selectedFrame.tent} м</span></td>
										<td><span>{selectedFrame.tent} м</span></td>
										<td className='price'>{price(selectedFrame.framePrice)} руб</td>
										<td><a href="#" onClick={(e) => this.setStage(e, 1)}><img src={edit} alt="Изменить" /></a></td>
									</tr>
									<tr>
										<td className="desc">Цвет:<span>{selectedFrame.selectedColor}</span></td>
										<td><span>{selectedFrame.selectedColor}</span></td>
										<td className='price'>
											{selectedFrame.colorPrice == 0 ?
												"без доплаты"
												:
												`${price(selectedFrame.colorPrice)} руб`}
										</td>
										<td><a href="#" onClick={(e) => this.setStage(e, 1)}><img src={edit} alt="Изменить" /></a></td>
									</tr>
									{
										winter?.size ?
											<tr>
												<td className="desc">Зимний комплект:</td>
												<td></td>
												<td className='price'>{
													winter ?
														`${price(winter.price)} руб`
														:
														null
												}
												</td>
												<td><a href="#" onClick={(e) => this.setStage(e, 2)}><img src={edit} alt="Изменить" /></a></td>
											</tr>
											:
											null
									}
									{
										curtains?.label ?
											<tr>
												<td className="desc">
													<div className="main">Торцевые шторы:</div>
													<div className="sub">
														{curtains?.label}
													</div>
												</td>
												<td></td>
												<td className='price'>
													{
														`${price(curtains.price)} руб`
													}
												</td>
												<td><a href="#" onClick={(e) => this.setStage(e, 3)}><img src={edit} alt="Изменить" /></a></td>
											</tr>
											:
											null
									}

									{
										roofWindow?.label ?
											<tr>
												<td className="desc">
													<div className="main">Окна в кровле:</div>
													<div className="sub">
														{
															roofWindow.label ?
																`${roofWindow.label.replace(" окна", "")} ${roofWindow.size} ${roofWindow.count} шт.`
																:
																null
														}
													</div>
												</td>
												<td></td>
												<td className='price'>
													{
														`${price(roofWindow.sum)} руб`
													}
												</td>
												<td><a href="#" onClick={(e) => this.setStage(e, 4)}><img src={edit} alt="Изменить" /></a></td>
											</tr>
											:
											null
									}
									{
										wallWindow?.size ?
											<tr>
												<td className="desc">
													<div className="main">Окна в стене:</div>
													<div className="sub">
														{
															wallWindow.label ?
																`${wallWindow.label.replace(" окно", "")} ${wallWindow.size} ${wallWindow.count} шт.`
																:
																null
														}
													</div>
												</td>
												<td></td>
												<td className='price'>{
													wallWindow ?
														`${price(wallWindow?.sum)} руб`
														:
														null
												}
												</td>
												<td><a href="#" onClick={(e) => this.setStage(e, 5)}><img src={edit} alt="Изменить" /></a></td>
											</tr> :
											null
									}
								</tbody>
							</table>
							<div className="total">
								<table>
									<tbody>
										<tr>
											<td>Общая стоимость вашего модуля</td>
											<td>{price(total)} руб.</td>
										</tr>
										<tr>
											<td>Количество модулей:</td>
											<td>
												<div className="counter">
													<div>{countItems} шт</div>
													<div className="total-btns">
														<a href="#" onClick={(e) =>
														{
															e.preventDefault();
															this.counter(-1);
														}}>-</a>
														<a href="#" onClick={(e) =>
														{
															e.preventDefault();
															this.counter(1);
														}}>+</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Итоговая стоимость</td>
											<td>{price(total * countItems)} руб.</td>
										</tr>
									</tbody>
								</table>
								<a
									href="#"
									className='final-btn'
									onClick={this.togglePopUp}
								>
									Оформить заказ
								</a>
							</div>
						</div>
					</div>
				</div >
				{
					showPopUp ?
						<Popup
							{...this.props}
							/* 							roof={roofWindow.label ?
															`${roofWindow.label.replace(" окна", "")} ${roofWindow.size} ${roofWindow.count} шт.`
															: ""}
														wall={wallWindow?.label ?
															`${wallWindow.label.replace(" окно", "")} ${wallWindow.size} ${wallWindow.count} шт.`
															: ""} */
							amount={total}
							amountFull={total * countItems}
							countItems={countItems}
							togglePopUp={this.togglePopUp}
						/>
						:
						null
				}
			</>
		)
	}
}