import React from 'react';

import Item from './Item';
import ItemWinter from './ItemWinter';
import ItemCurtain from './ItemCurtain';
import ItemWindow from './ItemWindow';
import Config from './Config';
import ConfigFinal from './ConfigFinal';

import {objects} from "../constants";
import logo from "../imgs/logo.svg";

class Main extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			selectedFrame: null,
			winter: null,
			curtains: null,
			roofWindow: null,
			wallWindow: null,
		}
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps.currStage == 3 && this.props.currStage == 2 && !this.state.selectedFrame.winter)
		{
			this.props.setStage(1);
		}
		if (prevProps.currStage == 6 && this.props.currStage == 5 && !this.state.selectedFrame.wallWindow)
		{
			this.props.setStage(4);
		}
	}


	setSelected = (e, obj, currColor) =>
	{
		e.preventDefault();
		let selected = {...obj};

		selected.colorPrice = obj.colors.find(el => el.label == currColor).price;
		selected.selectedColor = currColor;

		this.setState({
			selectedFrame: {...selected},
			winter: null,
			curtains: null,
			roofWindow: null,
			wallWindow: null,
		});

		if (obj.winter)
		{
			this.props.setStage(2);
		} else
		{
			this.props.setStage(3);
		}
	}

	setWinter = (e, obj) =>
	{
		e.preventDefault();
		this.setState({winter: obj});
		this.props.setStage(3);
	}

	setCurtain = (e, obj) =>
	{
		e.preventDefault();
		this.setState({curtains: obj});
		this.props.setStage(4);
	}

	setWindowRoof = (e, obj) =>
	{
		this.setState({roofWindow: obj});
		if (this.state.selectedFrame.wallWindow)
		{
			this.props.setStage(5);
		}
		else
		{
			this.props.setStage(6);
		}
	}
	setWindowWall = (e, obj) =>
	{
		//e.preventDefault();
		this.setState({wallWindow: obj});
		this.props.setStage(6);
	}

	clickReturn = (e) =>
	{
		e.preventDefault();
		this.props.setStage(this.props.currStage - 1);
	}

	renderBtns = (label, callback) =>
	{
		return (
			<div className="bottom-btns">
				<a
					href="#"
					onClick={(e) => this.clickReturn(e)}
					draggable={false}
					className={"white-btn"}
				>Назад</a>
				<a
					href="#"
					onClick={(e) =>
					{
						e.preventDefault();
						callback(e, {price: 0, sum: 0})
					}}
					draggable={false}
					className={"gray-btn"}
				>{label}</a>
			</div>
		)
	}

	renderConfig = (Component = Config) =>
	{
		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
		} = this.state;
		const {
			toggleMenu,
			isMenuOpen,
			setStage,
			currStage,
			maxStage
		} = this.props;

		return (
			<Component
				maxStage={maxStage}
				currStage={currStage}
				toggleMenu={toggleMenu}
				isMenuOpen={isMenuOpen}
				setStage={setStage}
				selectedFrame={selectedFrame}
				winter={winter}
				curtains={curtains}
				roofWindow={roofWindow}
				wallWindow={wallWindow}
			/>
		)
	}

	render()
	{
		const {
			currStage,
			setStage,
		} = this.props;
		const {

			selectedFrame,
		} = this.state;

		switch (currStage)
		{
			case 1:
				return (
					<main>
						<h3 className="title">
							1. Выберите каркас и тент
						</h3>
						<div className="item-list main">
							{
								objects.map(obj => (
									<Item
										setSelected={this.setSelected}
										key={obj.id}
										obj={obj}
									/>
								))
							}
							<div className="item logo">
								<img src={logo} alt="logo" />
							</div>
						</div>
					</main >
				);
			case 2:
			case 3:
				if (currStage === 2 && selectedFrame.winter)
				{
					return (
						<main>
							<div className="content-wrapper">
								<div className="wrapper">
									<h3 className="title">
										2. Выберите зимний комплект
									</h3>
									<div className="item-list winter">
										<ItemWinter
											key={selectedFrame.winter.label}
											setSelected={this.setWinter}
											obj={selectedFrame.winter}
										/>
									</div>
								</div>
								{this.renderConfig()}
							</div>
							{this.renderBtns("Без зимнего комлекта", this.setWinter)}
						</main>
					)
				}
				return (
					<main>
						<div className="content-wrapper">
							<div className="wrapper">
								<h3 className="title">
									3. Выберите торцевые шторы
								</h3>
								<div className="item-list curtains">
									{
										selectedFrame.curtains.map(obj => (
											<ItemCurtain
												key={obj.label}
												obj={obj}
												setSelected={this.setCurtain}
											/>
										))
									}
								</div>
							</div>
							{this.renderConfig()}
						</div>
						{this.renderBtns("Без торцевых штор", this.setCurtain)}
					</main>
				)
			case 4:
				return (
					<main>
						<div className="content-wrapper">
							<div className="wrapper">
								<h3 className="title">
									4. Выберите окна в кровле
								</h3>
								<div className="item-list ">
									{
										selectedFrame.roofWindow.map(obj => (
											<ItemWindow
												key={obj.label}
												obj={obj}
												setSelected={this.setWindowRoof}
											/>
										))
									}
								</div>
							</div>
							{this.renderConfig()}
						</div>
						{this.renderBtns("Без окон в кровле", this.setWindowRoof)}
					</main>
				)
			case 5:
				return (
					<main>
						<div className="content-wrapper">
							<div className="wrapper">
								<h3 className="title">
									5. Выберите окна в стене
								</h3>
								<div className="item-list ">
									{
										selectedFrame.wallWindow?.map(obj => (
											<ItemWindow
												key={obj.label}
												obj={obj}
												setSelected={this.setWindowWall}
											/>
										))
									}
								</div>
							</div>
							{this.renderConfig()}
						</div>
						{this.renderBtns("Без окон в стене", this.setWindowWall)}
					</main>
				)
			case 6:
				return (
					<main>
						{this.renderConfig(ConfigFinal)}
					</main>
				)
		}
	}
}

export default Main;
