import React from 'react';

import Description from "./Description";

import {colors, objects} from "../constants";
import {price} from '../helper';
import winterImage from "../imgs/winter.png";


export default class ItemCurtain extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			showDesc: false,
		}
	}

	toggleDescription = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState((state) => {return {showDesc: !state.showDesc}});
	}


	render()
	{
		const {
			obj,
			setSelected
		} = this.props;

		const {
			showDesc
		} = this.state;

		if (showDesc)
		{
			return (
				<Description
					obj={obj}
					toggleDescription={this.toggleDescription}
				/>)
		}



		return (
			<div className="item">
				<div className="wrapper">
					<div className="content">
						<div className="img">
							<img src={winterImage} alt={"winter"} />
						</div>
						<div className="curt-name">
							{obj.label}
						</div>
						<div className="curt-desc">
							{obj.desc}
						</div>
						<div className="curt-price">
							{price(obj.price)} руб
						</div>
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={(e) => {setSelected(e, obj)}}
							draggable={false}
							className={"main-btn"}
						>Выбрать</a>
						<a
							href="#"
							onClick={this.toggleDescription}
							draggable={false}
							className={"second-btn"}
						>Подробнее</a>
					</div>
				</div>
			</div>
		)
	}
}