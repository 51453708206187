import React from 'react';

import Description from "./Description";

import {colors, objects} from "../constants";
import edit from "../imgs/edit.svg";
import {price} from '../helper';
import classNames from 'classnames';


export default class Config extends React.Component
{
	setStage = (e, num) =>
	{
		e.preventDefault();
		this.toggleMenu(e);
		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
		} = this.props;
		if (num == 2)
		{
			if (!selectedFrame.winter)
			{
				return
			}
		}
		if (num == 5)
		{
			if (!selectedFrame.wallWindow)
			{
				return
			}
		}
		this.props.setStage(num);
	}

	toggleMenu = (e) =>
	{
		e.preventDefault();
		this.props.toggleMenu();
		document.body.classList.toggle("_lock");
	}



	render()
	{
		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
			isMenuOpen,
			currStage,
			maxStage
		} = this.props;

		let totalCount = 7;
		if (!selectedFrame.winter)
		{
			totalCount--
		}
		if (!selectedFrame.wallWindow)
		{
			totalCount--
		}

		let count = (selectedFrame ? 3 : 0) + (winter ? 1 : 0) + (curtains ? 1 : 0) + (roofWindow ? 1 : 0) + (wallWindow ? 1 : 0);
		let percent = Math.ceil(count / totalCount * 100);

		let total = (selectedFrame ? selectedFrame.framePrice + selectedFrame.colorPrice : 0) +
			(winter ? winter.price : 0) +
			(curtains ? curtains.price : 0) +
			(roofWindow ? roofWindow.sum : 0) +
			(wallWindow ? wallWindow.sum : 0);
		let isFinal = currStage == 6;


		return (
			<div className={classNames("config", {"open": isMenuOpen})}>
				<a
					href='#'
					className={classNames("close", {"open": isMenuOpen, })}
					onClick={e => this.toggleMenu(e)}></a>
				<h3 className="title">
					{
						isFinal ?
							"Ваша конфигурация" :
							"Конфигурация"
					}
				</h3>
				<div className="name">Модуль: {selectedFrame?.frame}</div>
				<div className={classNames("subtitle")}>
					Выбраны не все обязательные компоненты
				</div>

				<div className={classNames("progressbar", {"open": isMenuOpen, })}>
					<div className="load" style={{width: `${percent}%`}}></div>
				</div>
				<div className="separator">
					<div className="left">
						<div className="img">
							<img src={colors[selectedFrame.selectedColor].imgUrl} alt={selectedFrame.selectedColor} />
						</div>
					</div>
					<div className="right">

						<table>
							<tbody>
								<tr>
									<td className="desc">Каркас:</td>
									<td>{selectedFrame.frame} м</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td className="desc">Тент:</td>
									<td>{selectedFrame.tent} м</td>
									<td>{price(selectedFrame.framePrice)} руб</td>
									<td><a href="#" onClick={(e) => this.setStage(e, 1)}><img src={edit} alt="Изменить" /></a></td>
								</tr>
								<tr>
									<td className="desc">Цвет:</td>
									<td>{selectedFrame.selectedColor}</td>
									<td>{price(selectedFrame.colorPrice)} руб</td>
									<td><a href="#" onClick={(e) => this.setStage(e, 1)}><img src={edit} alt="Изменить" /></a></td>
								</tr>
								{
									selectedFrame.winter && winter?.size ?
										<tr>
											<td className="desc">Зимний комплект:</td>
											<td>{winter?.size}</td>
											<td>{
												winter ?
													`${price(winter?.price)} руб`
													:
													null
											}
											</td>
											<td><a href="#" onClick={(e) => this.setStage(e, 2)}><img src={edit} alt="Изменить" /></a></td>
										</tr>
										: null
								}
								{
										curtains?.label ?
										<tr>
											<td className="desc">Торцевые шторы:</td>
											<td>{curtains?.label}</td>
											<td>{
												curtains ?
													`${price(curtains?.price)} руб`
													:
													null
											}
											</td>
											<td><a href="#" onClick={(e) => this.setStage(e, 3)}><img src={edit} alt="Изменить" /></a></td>
										</tr>
										:
										null
								}
								{
									roofWindow?.label ?
										<tr>
											<td className="desc">Окна в кровле:</td>
											<td>{
												roofWindow && roofWindow.label ?
													`${roofWindow.label.replace(" окна", "")} ${roofWindow.size} ${roofWindow.count} шт.`
													:
													null
											}</td>
											<td>{
												roofWindow ?
													`${price(roofWindow?.sum)} руб`
													:
													null
											}
											</td>
											<td><a href="#" onClick={(e) => this.setStage(e, 4)}><img src={edit} alt="Изменить" /></a></td>
										</tr>
										:
										null
								}
								{
									selectedFrame.wallWindow && wallWindow?.label ?
										<tr>
											<td className="desc">Окна в стене:</td>
											<td>{
												wallWindow && wallWindow.label ?
													`${wallWindow.label.replace(" окно", "")} ${wallWindow.size} ${wallWindow.count} шт.`
													:
													null
											}</td>
											<td>{
												wallWindow ?
													`${price(wallWindow?.sum)} руб`
													:
													null
											}
											</td>
											<td><a href="#" onClick={(e) => this.setStage(e, 5)}><img src={edit} alt="Изменить" /></a></td>
										</tr>
										:
										null
								}
							</tbody>
						</table>
						<div className="total">
							<div className="text">Итоговая цена:</div>
							<div className="value">{price(total)} руб.</div>
						</div>
					</div>
				</div>

			</div >
		)
	}
}