import React from 'react';
import classNames from 'classnames';



export default class Popup extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
		}
	}

	onClick = (e) =>
	{
		e.preventDefault();
		this.props.callback();
	}

	render()
	{
		const {
			togglePopUp,
			label,
			flag
		} = this.props;

		return (
			<a
				href='#'
				className={classNames('wind-btn', {"on": flag})}
				onClick={this.onClick}
			>
				{label}
			</a>
		)
	}
}