import classNames from "classnames";

import returnIcon from "../imgs/path9417.svg";
import burgerIcon from "../imgs/Menu_1_.svg";

function Header(props)
{
	const toggleMenu = (e) =>
	{
		e.preventDefault();
		props.toggleMenu();
		document.body.classList.toggle("_lock");
	}

	const setStage = (e) =>
	{
		e.preventDefault();
		props.setStage(props.currStage - 1);
	}

	if (props.currStage == 6) return null;

	return (
		<header className={classNames({"not-first": props.currStage > 1})}>
			<a href={"#"} className="return" onClick={(e) =>
			{
				if (props.isMenuOpen) 
				{
					e.preventDefault();
					return;
				}
				setStage(e)
			}}>
				<img src={returnIcon} alt="Вернуться" />
			</a>
			<h1 className="title">
				Конфигуратор
			</h1>
			<h2 className={classNames("subtitle", {"hide": props.currStage > 1})}>
				Собери свой модуль за 7 шагов!
			</h2>
			<a href={"#"} className="burger" onClick={(e) => toggleMenu(e)}>
				<img src={burgerIcon} alt="Меню" />
			</a>
		</header>

	);
}

export default Header;