import React from 'react';

import Description from "./Description";
import WindowBtn from "./WindowBtn";

import {windows} from "../constants";
import {price} from '../helper';
import winterImage from "../imgs/winter.png";
import classNames from 'classnames';



export default class ItemWindow extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			showDesc: false,
			left: this.createObj(this.props.obj.maxLeft),
			right: this.createObj(this.props.obj.maxRight),
			totalCount: 0,
		}
	}

	createObj = (count) =>
	{
		let arr = [];
		for (let i = 0; i < count; i++)
		{
			arr.push({flag: 0})
		}
		return arr;
	}

	toggleDescription = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState((state) => {return {showDesc: !state.showDesc}});
	}

	setCount = () =>
	{
		let count = 0;
		const {
			left,
			right
		} = this.state;

		for (let el of left)
		{
			if (el.flag) count++
		}
		for (let el of right)
		{
			if (el.flag) count++
		}

		this.setState({totalCount: count})
	}

	setSelected = (e, obj) =>
	{
		e.preventDefault();
		const {
			totalCount,
			left,
			right,
		} = this.state;

		let newObj = {...obj};
		if (totalCount == 0) return;

		newObj.sum = totalCount * obj.price;
		newObj.count = totalCount;
		newObj.selectedR = right;
		newObj.selectedL = left;

		this.props.setSelected(e, newObj)
	}

	toggleWindowLeft = (i) =>
	{
		this.state.left[i].flag = !this.state.left[i].flag;
		this.setCount();
		this.forceUpdate();
	}
	toggleWindowRight = (i) =>
	{
		this.state.right[i].flag = !this.state.right[i].flag;
		this.setCount();
		this.forceUpdate();
	}


	render()
	{
		const {
			obj,
		} = this.props;

		const {
			showDesc,
			totalCount
		} = this.state;

		if (showDesc)
		{
			return (
				<Description
					obj={obj}
					toggleDescription={this.toggleDescription}
				/>)
		}


		let totalPrice = price(obj.price * totalCount);



		return (
			<div className="item">
				<div className="wrapper">
					<div className="content">
						<div className="img">
							<img src={windows[obj.label].imgUrl} alt={"winter"} />
						</div>
						<div className="curt-name">
							{obj.label}
						</div>
						<div className="curt-desc">
							{obj.size}
						</div>
						<div className="window-btns">
							<div className="helper-container">
								<div className="helper">
									?
								</div>
								<div className="helper-content">
									<div className="row">
										макс. кол-во слева = {obj.maxLeft} шт
									</div>
									<div className="row">
										макс. кол-во справа = {obj.maxRight} шт
									</div>
									<div className="row">
										Общее кол-во = {obj.maxLeft + obj.maxRight} шт
									</div>
								</div>
							</div>
							<div className="btns">
								<div className="col">
									{
										this.state.left.map((el, i) => (
											<WindowBtn
												key={i}
												flag={el.flag}
												label={`Л${i + 1}`}
												callback={() => this.toggleWindowLeft(i)}
											/>
										))
									}
								</div>
								<div className="col">
									{
										this.state.right.map((el, i) => (
											<WindowBtn
												key={i}
												flag={el.flag}
												label={`П${i + 1}`}
												callback={() => this.toggleWindowRight(i)}
											/>
										))
									}
								</div>
							</div>
							Вход
						</div>
						{
							totalCount > 0 ?
								<div className="curt-price">
									{totalPrice} руб.
								</div>
								:
								<div className="curt-price">
									{price(obj.price)} руб. шт
								</div>
						}
					</div>
					<div className="btns">
						<a
							href="#"
							onClick={(e) => {this.setSelected(e, obj)}}
							draggable={false}
							className={"main-btn"}
						>Выбрать</a>
						<a
							href="#"
							onClick={this.toggleDescription}
							draggable={false}
							className={"second-btn"}
						>Подробнее</a>
					</div>
				</div>
			</div>
		)
	}
}