import React from 'react';

import Description from "./Description";

import {colors, objects} from "../constants";
import {price} from '../helper';
import winterImage from "../imgs/winter.png";


export default class ItemWinter extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			showDesc: false,
		}
	}

	toggleDescription = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState((state) => {return {showDesc: !state.showDesc}});
	}

	setColor = (e, label) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState({currColor: label});
	}

	render()
	{
		const {
			obj,
			setSelected
		} = this.props;

		return (
			<div className="item">
				<div className="img">
					<img src={winterImage} alt={"winter"} />
				</div>
				<table>
					<tbody>
						<tr>
							<td className="desc">Модуль:</td>
							<td>{obj.size} м</td>
						</tr>
						<tr>
							<td className="desc">Цена:</td>
							<td>{price(obj.price)} руб</td>
						</tr>
					</tbody>
				</table>
				<div className="btns">
					<a
						href="#"
						onClick={(e) => {setSelected(e, obj)}}
						draggable={false}
						className={"main-btn"}
					>Выбрать</a>
				</div>
			</div>
		)
	}
}