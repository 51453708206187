import React from 'react';

import './null.scss';
import './App.scss';

import Header from './components/Header';
import Main from './components/Main';


class App extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			maxStage: 1,
			currStage: 1,
			isMenuOpen: false,
		}
	}

	toggleMenu = () =>
	{
		this.setState((state) => ({isMenuOpen: !state.isMenuOpen}))
	}

	setStage = (newStage) => 
	{
		this.setState((state) =>
		{
			let maxStage = state.maxStage > newStage ? state.maxStage : newStage;

			return {
				currStage: newStage,
				maxStage: maxStage,
			}
		}
		);
	}



	render()
	{
		const {
			currStage,
			isMenuOpen,
			maxStage,
		} = this.state;



		return (
			<div className="App" >
				<Header
					isMenuOpen={isMenuOpen}
					setStage={this.setStage}
					toggleMenu={this.toggleMenu}
					currStage={currStage}
				/>
				<Main
					maxStage={maxStage}
					toggleMenu={this.toggleMenu}
					isMenuOpen={isMenuOpen}
					currStage={currStage}
					setStage={this.setStage}
				/>
			</div>
		);
	}
}

export default App;
