import yellowPic from "./imgs/Желтый.jpg"
import greenPic from "./imgs/Зеленый.jpg"
import redPic from "./imgs/Красный.jpg"
import whitePic from "./imgs/Белый.jpg"
import grayPic from "./imgs/Серый.jpg"
import beigePic from "./imgs/Бежевый.png"
import win1 from "./imgs/win1.png"
import win2 from "./imgs/win2.png"
import win3 from "./imgs/win3.png"
import win4 from "./imgs/win4.png"

export const objects = [
	{
		id: 1,
		tent: "4x4",
		frame: "4x4",
		tentPrice: 0,
		framePrice: 145000,
		description: {
			char: "Кровельный тент для каркаса 4х4м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 7000,
			},
			{
				label: "Красный",
				price: 7000,
			},
			{
				label: "Зеленый",
				price: 7000,
			},
			{
				label: "Желтый",
				price: 7000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 52000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 80000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 2,
				maxRight: 2,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 2,
				maxRight: 2,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
	},
	{
		id: 2,
		tent: "6x4",
		frame: "6x4",
		tentPrice: 0,
		framePrice: 185000,
		description: {
			char: "Кровельный тент для каркаса 6х4м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 9000,
			},
			{
				label: "Красный",
				price: 9000,
			},
			{
				label: "Зеленый",
				price: 9000,
			},
			{
				label: "Желтый",
				price: 9000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 52000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 80000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
	},
	{
		id: 3,
		tent: "8x4",
		frame: "8x4",
		tentPrice: 0,
		framePrice: 235000,
		description: {
			char: "Кровельный тент для каркаса 8х4м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 11000,
			},
			{
				label: "Красный",
				price: 11000,
			},
			{
				label: "Зеленый",
				price: 11000,
			},
			{
				label: "Желтый",
				price: 11000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 52000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 80000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
	},
	{
		id: 4,
		tent: "6x6",
		frame: "6x6",
		tentPrice: 0,
		framePrice: 250000,
		description: {
			char: "Кровельный тент для каркаса 6х6м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 13000,
			},
			{
				label: "Красный",
				price: 13000,
			},
			{
				label: "Зеленый",
				price: 13000,
			},
			{
				label: "Желтый",
				price: 13000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 108000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 136000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		wallWindow: [
			{
				label: "Глухое окно",
				size: "1400х2000 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Подкатное окно",
				size: "D1200 мм",
				maxLeft: 3,
				maxRight: 3,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: " Герметичная вклейка в кровельный тент, возможность скатывания секции вверх",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		winter: {
			price: 220000,
			size: "6x6",
		}
	},
	{
		id: 5,
		tent: "8x6",
		frame: "8x6",
		tentPrice: 0,
		framePrice: 320000,
		description: {
			char: "Кровельный тент для каркаса 8х6м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 16000,
			},
			{
				label: "Красный",
				price: 16000,
			},
			{
				label: "Зеленый",
				price: 16000,
			},
			{
				label: "Желтый",
				price: 16000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 108000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 136000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		wallWindow: [
			{
				label: "Глухое окно",
				size: "1400х2000 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Подкатное окно",
				size: "D1200 мм",
				maxLeft: 4,
				maxRight: 4,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: " Герметичная вклейка в кровельный тент, возможность скатывания секции вверх",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		winter: {
			price: 295000,
			size: "8x6",
		},
	},
	{
		id: 6,
		tent: "10x6",
		frame: "10x6",
		tentPrice: 0,
		framePrice: 380000,
		description: {
			char: "Кровельный тент для каркаса 10х6м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 19000,
			},
			{
				label: "Красный",
				price: 19000,
			},
			{
				label: "Зеленый",
				price: 19000,
			},
			{
				label: "Желтый",
				price: 19000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 108000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 136000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 5,
				maxRight: 5,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 5,
				maxRight: 5,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		wallWindow: [
			{
				label: "Глухое окно",
				size: "1400х2000 мм",
				maxLeft: 5,
				maxRight: 5,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Подкатное окно",
				size: "D1200 мм",
				maxLeft: 5,
				maxRight: 5,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: " Герметичная вклейка в кровельный тент, возможность скатывания секции вверх",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		winter: {
			price: 365000,
			size: "10x6",
		},
	},
	{
		id: 7,
		tent: "10x6",
		frame: "12x6",
		tentPrice: 0,
		framePrice: 440000,
		description: {
			char: "Кровельный тент для каркаса 12х6м.",
			material: "водонепроницаемый ПВХ с защитой от ультрафиолета",
			bracing: " люверсы D1см по периметру, такелаж",
		},
		colors: [
			{
				label: "Бежевый",
				price: 0,
			},
			{
				label: "Серый",
				price: 0,
			},
			{
				label: "Белый",
				price: 22000,
			},
			{
				label: "Красный",
				price: 22000,
			},
			{
				label: "Зеленый",
				price: 22000,
			},
			{
				label: "Желтый",
				price: 22000,
			}
		],
		curtains: [
			{
				label: "Шторы из синтетической ткани",
				desc: `Tорцевые шторы из синтетической
				ткани(комплект 2шт.) `,
				price: 108000,
				material: "Синтетическая ткань с полеуретановым покрытием дублированная москитной сеткой",
				addValue: "молния по центру шторы",
				bracing: "стропы",
				add: "Вход:",
			},
			{
				label: "Шторы из прозрачного ПВХ",
				desc: `Tорцевые шторы из прозрачного ПВХ (комплект 2шт.) `,
				price: 136000,
				material: "Прозрачный ПВХ дублированный шторой из смесовой ткани и москитной сеткой",
				addValue: "молния по центру шторы",
				add: "Вход:",
				bracing: "стропы",
			}
		],
		roofWindow: [
			{
				label: "Прямоугольные окна",
				size: "1400х2000 мм",
				maxLeft: 6,
				maxRight: 6,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Круглые окна",
				size: "D1200 мм",
				maxLeft: 6,
				maxRight: 6,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		wallWindow: [
			{
				label: "Глухое окно",
				size: "1400х2000 мм",
				maxLeft: 6,
				maxRight: 6,
				price: 10000,
				material: "Прозрачный ПВХ",
				bracing: "Герметичная вклейка в кровельный тент",
				add: "Размер",
				addValue: "1400х2000 мм",
			},
			{
				label: "Подкатное окно",
				size: "D1200 мм",
				maxLeft: 6,
				maxRight: 6,
				price: 12000,
				material: "Прозрачный ПВХ",
				bracing: " Герметичная вклейка в кровельный тент, возможность скатывания секции вверх",
				add: "Диаметр",
				addValue: "1200 мм",
			},
		],
		winter: {
			price: 430000,
			size: "12x6",
		},
	}
]

export const colors = {
	"Желтый": {
		style: {background: "#EDBE47"},
		styleSelected: {background: "#EDBE47", border: "1px solid #959595"},
		imgUrl: yellowPic
	},
	"Зеленый": {
		style: {background: "#47A725"},
		styleSelected: {background: "#47A725", border: "1px solid #959595"},
		imgUrl: greenPic
	},
	"Красный": {
		style: {background: "#D02B2B"},
		styleSelected: {background: "#D02B2B", border: "1px solid #959595"},
		imgUrl: redPic
	},
	"Белый": {
		style: {background: "#FFFFFF", border: "1px solid #959595"},
		styleSelected: {background: "#FFFFFF", border: "2px solid #959595"},
		imgUrl: whitePic
	},
	"Серый": {
		style: {background: "#E5E5E5"},
		styleSelected: {background: "#E5E5E5", border: "1px solid #959595"},
		imgUrl: grayPic
	},
	"Бежевый": {
		style: {background: "#FFD2A8"},
		styleSelected: {background: "#FFD2A8", border: "1px solid #959595"},
		imgUrl: beigePic
	}
}

export const windows = {
	"Прямоугольные окна": {
		imgUrl: win1
	},
	"Круглые окна": {
		imgUrl: win2
	},
	"Глухое окно": {
		imgUrl: win3
	},
	"Подкатное окно": {
		imgUrl: win4
	}
}