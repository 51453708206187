import React from 'react';

import Description from "./Description";

import {colors, objects} from "../constants";
import {price} from '../helper';


export default class Item extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			currColor: "Белый",
			showDesc: false,
		}
	}

	toggleDescription = (e) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState((state) => {return {showDesc: !state.showDesc}});
	}

	setColor = (e, label) =>
	{
		e.preventDefault();
		e.stopPropagation();
		this.setState({currColor: label});
	}

	render()
	{
		const {
			obj,
			setSelected
		} = this.props;

		const {
			currColor,
			showDesc
		} = this.state;

		let colorPrice = obj.colors.find(color => color.label == currColor).price;

		if (showDesc)
		{
			return (
				<Description
					obj={obj}
					currColor={currColor}
					toggleDescription={this.toggleDescription}
				/>)
		}
		return (
			<div className="item">
				<div className="img">
					<img src={colors[currColor].imgUrl} alt={currColor} />
				</div>
				<table>
					<tbody>
						<tr>
							<td className="desc">Тент:</td>
							<td>{obj.tent} м</td>
						</tr>
						<tr>
							<td className="desc">Каркас:</td>
							<td>{obj.frame} м</td>
						</tr>
						<tr>
							<td className="desc">Цена:</td>
							<td>{price(obj.framePrice + colorPrice)} руб</td>
						</tr>
						<tr>
							<td className="desc">Цвет:</td>
							<td>{currColor}</td>
						</tr>
					</tbody>
				</table>
				<div className="colors">
					{
						obj.colors.map(obColor =>
						{
							let style = obColor.label == currColor
								? colors[obColor.label].styleSelected
								: colors[obColor.label].style
							return (
								<a
									key={obColor.label}
									title={obColor.label}
									className={"color"}
									href="#"
									draggable={false}
									onClick={e => this.setColor(e, obColor.label)}
									style={style}
								>
								</a>
							)
						})}
				</div>
				<div className="color-price">
					+ {price(colorPrice)} руб
				</div>
				<div className="btns">
					<a
						href="#"
						onClick={(e) => setSelected(e, obj, currColor)}
						draggable={false}
						className={"main-btn"}
					>Выбрать</a>
					<a
						href="#"
						onClick={this.toggleDescription}
						draggable={false}
						className={"second-btn"}
					>Подробнее</a>
				</div>
			</div>
		)
	}
}