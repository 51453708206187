import React from 'react';

import {colors, objects} from "../constants";
import {price} from '../helper';


export default class Description extends React.Component
{
	render()
	{
		const {
			currColor,
			obj,
			toggleDescription,
		} = this.props;

		return (
			<div className="item">
				<a href="#" draggable={false} className="close" onClick={toggleDescription}>
				</a>
				<div className="row">
					<div className="subtitle">
						Характеристики:
					</div>
					<div className="description">
						{obj.description?.char || obj.char}
					</div>
				</div>
				<div className="row">
					<div className="subtitle">
						Материал:
					</div>
					<div className="description">
						{obj.description?.material || obj.material}
					</div>
				</div>
				<div className="row">
					<div className="subtitle">
						{
							currColor ?
								"Цвет:"
								:
								obj.add
						}

					</div>
					<div className="description">
						{currColor || obj.addValue}
					</div>
				</div>
				<div className="row">
					<div className="subtitle">
						Крепление:
					</div>
					<div className="description">
						{obj.description?.bracing || obj.bracing}
					</div>
				</div>
			</div>
		)
	}
}