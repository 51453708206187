import React from 'react';

import {IMaskInput} from 'react-imask';

import Description from "./Description";

import {colors, objects} from "../constants";
import {price} from '../helper';
import closeSvg from "../imgs/close.svg";


export default class Popup extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
			phone: "",
			email: "",
			name: "",
		}
	}

	changePhone = (e) =>
	{
		let val = e;
		this.setState({phone: val})
	}
	changeEmail = (e) =>
	{
		let val = e.target.value;
		this.setState({email: val})
	}
	changeName = (e) =>
	{
		let val = e.target.value;
		this.setState({name: val})
	}

	sendMail = async (e) =>
	{
		e.preventDefault();

		const {
			selectedFrame,
			winter,
			curtains,
			roofWindow,
			wallWindow,
			amount,
			amountFull,
			countItems,
		} = this.props;
		const {
			phone,
			email,
			name
		} = this.state;

		let roof = "";
		if (roofWindow)
		{
			roof = roofWindow.selectedR?.map((el, i) => el.flag ? `П${i + 1}` : "").join("|") + "|" +
				roofWindow.selectedL?.map((el, i) => el.flag ? `Л${i + 1}` : "").join("|") + " " + roofWindow.label + " " + roofWindow.size;
		}
		let wall = "";
		if (wallWindow)
		{
			wall = wallWindow?.selectedR?.map((el, i) => el.flag ? `П${i + 1}` : "").join("|") + "|" +
				wallWindow.selectedL?.map((el, i) => el.flag ? `Л${i + 1}` : "").join("|") + " " + wallWindow.label + " " + wallWindow.size;
		}

		let form = new FormData();
		form.append("frame", selectedFrame.frame + " " + selectedFrame.framePrice);
		form.append("tent", selectedFrame.tent + " " + selectedFrame.tentPrice);
		form.append("color", selectedFrame.selectedColor + " " + selectedFrame.colorPrice);
		form.append("winter", winter ? winter.size + " " + winter.price : "");
		form.append("curtains", curtains.label ? curtains.label + " " + curtains.price : "");
		form.append("roofWindow", roof);
		form.append("wallWindow", wall);

		form.append("phone", phone || "");
		form.append("email", email || "");
		form.append("name", name || "");

		form.append("amount", amount);
		form.append("amountFull", amountFull);
		form.append("countItems", countItems);

		let response = await fetch("/sendmail.php",
			{
				method: "POST",
				body: form
			});
		if (response.ok)
		{
			let result = await response.json();
			alert(result.message);
		}
		else
		{
			alert("Возникла ошибка при отправке");
		}

	}

	render()
	{
		const {
			togglePopUp
		} = this.props;

		const {
			phone,
			email,
			name,
		} = this.state;

		return (
			<div className="popup-wrapper">
				<div className="popup">
					<a href="#" className="close-svg"
						onClick={togglePopUp}
					>
						<img src={closeSvg} alt="" />
					</a>
					<IMaskInput
						mask={'+{7}(000)000-00-00'}
						//radix="."
						value={phone}
						unmask={true} // true|false|'typed'
						//ref={ref}
						inputRef={el => this.input = el}  // access to nested input
						// DO NOT USE onChange TO HANDLE CHANGES!
						// USE onAccept INSTEAD
						onAccept={
							// depending on prop above first argument is
							// `value` if `unmask=false`,
							// `unmaskedValue` if `unmask=true`,
							// `typedValue` if `unmask='typed'`
							(value, mask) => this.changePhone(value)
						}
						// ...and more mask props in a guide

						// input props also available
						placeholder='Телефон'
					/>
					{/* 					<input
						value={phone}
						type="text"
						placeholder='Телефон'
						onChange={this.changePhone}
					/> */}
					<input
						value={email}
						type="text"
						placeholder='Электронная почта'
						onChange={this.changeEmail}
					/>
					<input
						value={name}
						type="text"
						placeholder='Компания'
						onChange={this.changeName}
					/>
					<a
						href="#"
						className='final-btn'
						onClick={this.sendMail}
					>
						Отправить
					</a>
				</div>
			</div>
		)
	}
}